<template>
  <div>
    <Header/>
    <div class="main-content">
      <div class="page-content">
        <section class="section mt-4">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-3">

              </div>
              <div class="col-12 mt-4 mb-5">
                <p  v-html="form.text_front"></p>
              </div>
              <div class="col-12 mt-4 mb-5">
                <a href="https://hr.dkcapital.ge/Signup" class="btn btn-custom-primary mdi mdi-rocket-launch-outline"> რეგისტრაცია</a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "VacancyInfo",
  data(){
    return {
      form:{
        text_front:''
      }
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    LoadDesc() {
      axios.get('https://recruting.dkcapital.ge/api/public/static/loadData')
          .then(response => {
            this.form = response.data;
          });
    }
  },
    mounted(){
      this.LoadDesc();
    }

}
</script>

<style scoped>

</style>