<template>
  <div>
    <Header/>
    <div class="main-content">

      <div class="page-content">

        <!-- START JOB-DEATILS -->
        <section class="section mt-0 mt-md-4">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-8 mb-3">
                <h4 class="mb-1">ვაკანსიის გვერდი</h4>
              </div>
              <div class="col-4 mb-3 text-end">
                <a href="/#Vanacy" class="text-secondary"><i class="mdi mdi-arrow-left"></i> უკან</a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8">
                <div class="card job-detail overflow-hidden">
                  <div class="card-body p-4">
                    <div>
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex align-items-center">
<!--                            <div v-if="!vacancyDetails.CompanyDetail.logo">
                              <img :src="vacancyDetails.CompanyDetail.logo" alt="" class="img-fluid rounded-3 rounded-3 me-3" style="height: 55px">
                            </div>
                            <div v-if="vacancyDetails.CompanyDetail.logo">
                              <img src="../../assets/images/no-logo.png" alt="" class="img-fluid rounded-3 rounded-3 me-3" style="height: 55px">
                            </div>-->
                            <h5 class="mb-1">
                              {{vacancyDetails.PositionDetail.text}}
                            </h5>
                          </div>
                        </div>
                      </div><!--end row-->
                    </div>

                    <div class="mt-4">
                      <div class="row g-2">
                        <div class="col-lg-6">
                          <div class="border p-3">
                            <p class="text-muted fs-13 mb-0">სამუშაო გრაფიკი</p>
                            <span v-if="vacancyDetails.work_schedule=='remote'" class="badge bg-soft-blue fs-13 mt-1">დისტანციური</span>
                            <span v-if="vacancyDetails.work_schedule=='full'" class="badge bg-soft-green-dark fs-13 mt-1">სრული განაკვეთი</span>
                            <span v-if="vacancyDetails.work_schedule=='half'" class="badge bg-soft-warning fs-13 mt-1">ნახევარი განაკვეთი</span>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="border p-3">
                            <p class="text-muted fs-13 mb-0">სამუშაო გამოცდილება</p>
                            <p class="fw-medium mb-0 mdi mdi-clock-outline" v-if="vacancyDetails.work_experience=='1'">
                              გამოცდილების გარეშე
                            </p>
                            <p class="fw-medium mb-0 mdi mdi-clock-outline" v-if="vacancyDetails.work_experience=='2'">
                              დამწყები
                            </p>
                            <p class="fw-medium mb-0 mdi mdi-clock-outline" v-if="vacancyDetails.work_experience=='3'">
                              საშუალო დონე
                            </p>
                            <p class="fw-medium mb-0 mdi mdi-clock-outline" v-if="vacancyDetails.work_experience=='4'">
                              პროფესიონალი
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="border p-3">
                            <p class="text-muted fs-13 mb-0">ანაზღაურების ტიპი</p>
                            <p v-if="vacancyDetails.amount_type==1" class="mdi mdi-wallet-travel fw-medium mb-0">
                              ფიქსირებული + ბონუსი
                            </p>
                            <p v-if="vacancyDetails.amount_type==2" class="mdi mdi-wallet-travel fw-medium mb-0">
                              გამომუშავებით
                            </p>
                            <p v-if="vacancyDetails.amount_type==3" class="mdi mdi-wallet-travel fw-medium mb-0">
                              მხოლოდ ფიქსირებული
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-6" v-if="vacancyDetails.fixed_amount!=null">
                          <div class="border rounded-end p-3">
                            <p class="text-muted fs-13 mb-0">ფიქსირებული თანხა</p>
                            <p class="fw-medium mb-0 mdi mdi-wallet-outline"> {{ Number(vacancyDetails.fixed_amount).toLocaleString() }} {{vacancyDetails.CurrencyDetail.text}}</p>
                          </div>
                        </div>
                        <div class="col-lg-6"  v-if="vacancyDetails.amount_type==1">
                          <div class="border rounded-end p-3">
                            <p class="text-muted fs-13 mb-0">საშუალო ბონუსი</p>
                            <p class="fw-medium mb-0 mdi mdi-wallet-outline"> მინ {{ Number(vacancyDetails.min_bonus).toLocaleString() }} {{vacancyDetails.CurrencyDetail.text}} - მაქს {{ Number(vacancyDetails.max_bonus).toLocaleString() }} {{vacancyDetails.CurrencyDetail.text}}</p>
                          </div>

                        </div>
                        <div class="col-lg-6"  v-if="vacancyDetails.amount_type==2">
                          <div class="border rounded-end p-3">
                            <p class="text-muted fs-13 mb-0">საშუალო ბონუსი</p>
                            <p class="fw-medium mb-0 mdi mdi-wallet-outline"> მინ {{ Number(vacancyDetails.min_amount).toLocaleString() }} {{vacancyDetails.CurrencyDetail.text}} - მაქს {{ Number(vacancyDetails.max_amount).toLocaleString()  }} {{vacancyDetails.CurrencyDetail.text}}</p>
                          </div>

                        </div>
                        <div class="col-lg-6"  v-if="vacancyDetails.avarage_min_bonus!=null">
                          <div class="border rounded-end p-3">
                            <p class="text-muted fs-13 mb-0">საშუალო ჯამური შემოსავალი</p>
                            <p class="fw-medium mb-0 mdi mdi-wallet-outline"> მინ {{ Number(vacancyDetails.avarage_min_bonus).toLocaleString() }} {{vacancyDetails.CurrencyDetail.text}} - მაქს {{ Number(vacancyDetails.avarage_max_bonus).toLocaleString()  }} {{vacancyDetails.CurrencyDetail.text}}</p>
                          </div>

                        </div>



                      </div>
                    </div><!--end Experience-->

                    <div class="mt-4">
                      <h5 class="mb-3">რატომ უნდა იმუშაო ჩვენს კომპანიაში?</h5>
                      <div class="job-detail-desc">
                        <p class="text-muted mb-0">{{ vacancyDetails.why_should }}</p>
                      </div>
                    </div>


                    <div class="mt-4" >
                      <h5 class="mb-3">იზომება თუ არა შენი მუშაობის შედეგები?</h5>
                      <div class="job-detail-desc">
                        <p class="text-muted mb-0" v-if="vacancyDetails.result_to_work=='no'">არა არ იზომება.</p>
                        <p class="text-muted mb-0" v-if="vacancyDetails.result_to_work=='yes'">{{ vacancyDetails.what_result_should_you_achieve }}</p>
                      </div>
                    </div>




                    <div class="mt-4">
                      <h5 class="mb-3">რა უნდა გააკეთო?</h5>
                      <div class="job-detail-desc">
                        <p class="text-muted mb-0">{{ vacancyDetails.what_to_do }}</p>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="mb-3">რას გასწავლით?</h5>
                      <div class="job-detail-desc">
                        <p class="text-muted mb-0">{{ vacancyDetails.what_will_you_teach }}</p>
                      </div>
                    </div>



                    <div class="mt-4">
                      <h5 class="mb-3">რა პერსპექტივა გაქვთ ჩვენს კომპანიაში?</h5>
                      <div class="job-detail-desc mt-2">
                        <p class="text-muted">{{ vacancyDetails.perspective }}</p>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="mb-3">რა მოთხოვნებს უნდა აკმაყოფილებდე? </h5>
                      <div class="job-detail-desc mt-2">
                        <p class="text-muted">{{ vacancyDetails.requirement }}</p>
                      </div>
                    </div>



                    <div class="mt-4">
                      <h5 class="mb-3">დამატებითი ინფორმაცია</h5>
                      <div class="job-detail-desc mt-2">
                      <p>
                        {{ vacancyDetails.additional_info }}
                      </p>
                      </div>
                    </div>
                  </div><!--end card-body-->
                </div><!--end job-detail-->


              </div><!--end col-->

              <div class="col-lg-4 mt-4 mt-lg-0">
                <!--start side-bar-->
                <div class="side-bar ms-lg-4">
                  <div class="card job-overview">
                    <div class="card-body p-4">
                      <h6 class="fs-17">ზოგადი ინფორმაცია</h6>
                      <ul class="list-unstyled mt-4 mb-0">
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-briefcase icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">კატეგორია</h6>
                              <p class="text-muted mb-0">{{vacancyDetails.SphereDetail.text}}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-user icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">სამუშაო პოზიცია</h6>
                              <p class="text-muted mb-0">{{vacancyDetails.PositionDetail.text}}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-building icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ინდუსტრია</h6>
                              <p class="text-muted mb-0">{{vacancyDetails.IndustryDetail.text}}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-location-point icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ლოკაცია</h6>
                              <p class="text-muted mb-0"> {{vacancyDetails.CompanyDetail.address}}</p>
                            </div>
                          </div>
                        </li>


                        <li>
                          <div class="d-flex mt-4">
                            <i class="uil uil-history icon bg-soft-secondary"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ვაკანსიის დასრულების თარიღი</h6>
                              <p class="text-muted mb-0"> {{ vacancyDetails.EndDate }}</p>
                            </div>
                          </div>
                        </li>


                      </ul>
                      <div class="mt-3">
                        <a href="#applyNow" data-bs-toggle="modal" class="btn btn-custom-primary btn-hover w-100 mt-2">შეავსე აპლიკაცია <i class="uil uil-arrow-right"></i></a>
                      </div>
                    </div><!--end card-body-->
                  </div><!--end job-overview-->

                  <div class="card company-profile mt-4">
                    <div class="card-body p-4">
                      <div class="text-center">
                        <div v-if="vacancyDetails.CompanyDetail.logo!=''">
                          <img :src="vacancyDetails.CompanyDetail.logo" alt="" class="img-fluid rounded-3 rounded-3 me-3" style="height: 55px">
                        </div>
                        <div v-else>
                          <img src="../../assets/images/no-logo.jpeg" alt="" class="img-fluid rounded-3 rounded-3 me-3" style="height: 55px">
                        </div>

                        <div class="mt-4">
                          <h6 class="fs-17 mb-1">{{ vacancyDetails.CompanyDetail.name }}</h6>
                        </div>
                      </div>
                      <ul class="list-unstyled mt-4">
                        <li class="mt-3">
                          <div class="d-flex" style="word-break: break-word;">
                            <i class="uil uil-globe text-secondary fs-4"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ვებ გვერდი</h6>
                              <a :href="'https://'+vacancyDetails.CompanyDetail.website" style="    word-break: break-word;" target="_blank" class="text-muted fs-14 mb-0">{{vacancyDetails.CompanyDetail.website}}</a>
                            </div>
                          </div>
                        </li>
                        <li class="mt-3">
                          <div class="d-flex">
                            <i class="uil uil-map-marker text-secondary fs-4"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">ადგილმდებარეობა</h6>
                              <p class="text-muted fs-14 mb-0">{{vacancyDetails.CompanyDetail.address}}</p>
                            </div>
                          </div>
                        </li>
                        <li class="mt-3">
                          <div class="d-flex">
                            <i class="uil uil-facebook-f text-secondary fs-4"></i>
                            <div class="ms-3">
                              <h6 class="fs-14 mb-2">Facebook</h6>
                              <a :href="vacancyDetails.CompanyDetail.facebook" target="_blank" class="text-muted fs-14 mb-0">გადასვლა</a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!--end side-bar-->
              </div><!--end col-->
            </div><!--end row-->
          </div><!--end container-->
        </section>
        <!-- START JOB-DEATILS -->


      </div>
      <!-- End Page-content -->
      </div>

    <!-- START APPLY MODAL -->
    <div class="modal fade" id="applyNow" tabindex="-1" aria-labelledby="applyNow" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-5">
            <div class="text-center mb-4">
              <h5 class="modal-title" v-if="response_status!='true'" id="staticBackdropLabel">აპლიკაციის შევსება</h5>
            </div>
            <div class="position-absolute end-0 top-0 p-3">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <ValidationObserver v-slot="{ handleSubmit }">
            <form v-if="response_status!='true'" @submit.prevent="handleSubmit(addVacancy)">
              <div class="mb-3">
                <ValidationProvider name="Name Surname" rules="required" v-slot="{ errors }">
                  <label class="form-label">სახელი და გვარი</label>
                  <input type="text" class="form-control" v-model="form.name" placeholder="სახელი და გვარი">
                  <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>
                </ValidationProvider>

              </div>
              <div class="mb-3">
                <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
                  <label class="form-label">ელ.ფოსტა</label>
                  <input type="email" class="form-control" v-model="form.email" placeholder="ელ.ფოსტა">
                  <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>
                </ValidationProvider>

              </div>
              <div class="mb-3">
                <ValidationProvider name="Mobile" rules="required" v-slot="{ errors }">
                  <label class="form-label">მობილური</label>
                  <input type="number" class="form-control" v-model="form.mobile" placeholder="მობილური">
                  <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>
                </ValidationProvider>

              </div>
              <div class="mb-3">
                <ValidationProvider name="Message" rules="required" v-slot="{ errors }">
                  <label class="form-label">შეტყობინება</label>
                  <textarea class="form-control" v-model="form.message"></textarea>
                  <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>
                </ValidationProvider>

              </div>
              <div class="mb-4">
                <label class="form-label">ატვირთე შენი CV</label>
                <vue-dropzone ref="myVueDropzoneLogo"  id="dropzone-logo" useCustomSlot=true :headers="dropzoneCVOptions.headers" v-on:vdropzone-success="uploadCVSuccess" :options="dropzoneCVOptions">
                  <div class="dropzone-custom-content">
                    <div class="subtitle"><i class="tio-upload-on-cloud"></i> ატვირთე cv</div>
                  </div>
                </vue-dropzone>
              </div>
              <button type="submit" class="btn btn-custom-primary w-100">გააგზავნე აპლიკაცია</button>
            </form>
            </ValidationObserver>
            <div class="alert text-center" v-if="response_status=='true'">
                თქვენი CV წარმატებით გაიგზავნა.
            </div>
         </div>
        </div>
      </div>
    </div><!-- END APPLY MODAL -->

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: "VacancyDetails",
  data() {
    return {
      vacancyDetails: {
        CompanyDetail:{
          logo:''
        },
        IndustryDetail:{
          text:''
        },
        PositionDetail:{
          id:'',
          text:''
        },
        CurrencyDetail:{
          id:'',
          text:''
        },
        SphereDetail:{
          text:''
        }
      },
      error:false,
      errors:{},
      loading: true,
      errored: false,
      text:'',
      response_status:'',
      form:{
          'name': '',
          'file': '',
          'email': '',
          'mobile': '',
          'message': ''
      },
      dropzoneCVOptions: {

        url: 'https://recruting.dkcapital.ge/api/public/vacancy/upload',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,

      },
    }
  },
  methods: {
    vacancyDetail() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/get_by_id/' + this.$route.params.id)
          .then(res => {
            this.vacancyDetails = res.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    uploadCVSuccess: function(file, response) {
      this.form.file=response.success;
    },
    addVacancy() {
      axios.post('https://recruting.dkcapital.ge/api/public/vacancy/add_cv/'+ this.$route.params.id , this.form).then(
          res => {
            this.response_status='true';
          }
      ).catch(error => console.log(error))
    },
  },
  mounted() {
    this.vacancyDetail()
    console.log(this.$route.params.id)
  },
  components: {
    Header,
    Footer,
    vueDropzone: vue2Dropzone
  }
}
</script>

<style scoped>

</style>