<template>
  <!-- Begin page -->
  <div>

    <Header/>

    <div class="main-content">

      <div class="page-content">

        <!-- START HOME -->
        <section class="bg-home2 d-none d-sm-block" id="home">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-7">
                <div class="mb-4 pb-3 me-lg-5">

                  <h1 class="display-5 fw-semibold mb-3 d-block d-sm-none text-center">ჩვენ ვეხმარებით ადამიანებს დასაქმებაში და მეტი შემოსავლების მიღებაში <br></h1>
                  <h1 class="display-5 fw-semibold mb-3 d-none d-sm-block">ჩვენ ვეხმარებით ადამიანებს დასაქმებაში და მეტი შემოსავლების მიღებაში <br></h1>
                  <span class="display-5 text-custom-primary fw-bold d-none d-sm-block">დავით კენჭაძე</span>
                  <p class="lead text-muted mb-0 mt-3 d-none d-sm-block">ვაკანსიები, რომლებიც შეიძლება საინტერესო იყოს თქვენთვის ან თქვენი მეგობრებისთვის</p>
                </div>
              </div>
              <!--end col-->
              <div class="col-lg-5">
                <div class="mt-5 mt-md-0">
                  <img src="../../assets/images/process-04.jpg" alt="" class="home-img w-100 rounded-circle" />
                </div>
              </div><!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </section>
        <!-- End Home -->

        <!-- START HOME -->
        <div class="bg-home2 d-block d-sm-none mb-4" style="background-color: rgb(241 244 248);
    padding-top: 90px;
    padding-bottom: 60px;" id="home">
          <div class="container">
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                  <img src="../../assets/images/process-04.jpg" alt="" class="home-img w-50 rounded-circle" />
              </div><!--end col-->
              <div class="col-12 pt-4">
                <div class="mb-0 pb-0 pb-md-3 me-lg-5">

                  <h5 class="display-5 fw-semibold mb-0 d-block d-sm-none text-center" style="font-size: 21px;color: black;">ჩვენ ვეხმარებით ადამიანებს დასაქმებაში და მეტი შემოსავლების მიღებაში <br></h5>
                  <h5 class="display-5 fw-semibold mb-0 d-none d-sm-block" style="font-size: 25px;color: black;">ჩვენ ვეხმარებით ადამიანებს დასაქმებაში და მეტი შემოსავლების მიღებაში <br></h5>
                  <span class="display-5 text-custom-primary fw-bold d-none d-sm-block">დავით კენჭაძე</span>
                  <p class="lead text-muted mb-0 mt-3 text-center" style="    color: #303030!important;">ვაკანსიები, რომლებიც შეიძლება საინტერესო იყოს თქვენთვის ან თქვენი მეგობრებისთვის</p>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </div>
        <!-- End Home -->



        <!-- START JOB-LIST -->
        <section class="section" style="padding-top:0px!important;" id="Vanacy">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-6 col-lg-6">
                <div class="section-title text-start mb-0 pb-0">
                  <h4 class="title d-none d-sm-block">ვაკანსიები</h4>
                  <h4 class="title d-block d-sm-none" style="    font-size: 20px; line-height: 39px;">ვაკანსიები</h4>
                </div>

              </div>
              <div class="col-6 col-lg-6">
                <div class="section-title text-end mb-0 mt-2 pb-0">
                  <a href="/VacancyList" style="color:#023e79;">ყველა ვაკანსია</a>
                </div>

              </div>
              <!--end col-->
            </div><!--end row-->
<!--            <div class="row justify-content-center">
              <div class="col-lg-12">
                <ul class="job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4" id="pills-tab"
                    role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="recent-jobs-tab" data-bs-toggle="pill"
                            data-bs-target="#all-jobs" type="button" role="tab" aria-controls="all-jobs"
                            aria-selected="true">ბოლოს დამატებული</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link " id="recent-jobs-tab" data-bs-toggle="pill"
                            data-bs-target="#recent-jobs" type="button" role="tab" aria-controls="recent-jobs"
                            aria-selected="true">გამოცდილების გარეშე</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="remote-jobs-tab" data-bs-toggle="pill"
                            data-bs-target="#remote-jobs" type="button" role="tab" aria-controls="remote-jobs"
                            aria-selected="false">დამწყები</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="part-time-tab" data-bs-toggle="pill"
                            data-bs-target="#part-time" type="button" role="tab" aria-controls="part-time"
                            aria-selected="false">საშუალო დონე</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="full-time-tab" data-bs-toggle="pill"
                            data-bs-target="#full-time" type="button" role="tab" aria-controls="full-time"
                            aria-selected="false">პროფესიონალი</button>
                  </li>
                </ul>
              </div>
              &lt;!&ndash;end col&ndash;&gt;
            </div>-->
            <!--end row-->
            <div class="row">
              <div class="col-lg-12">
                <div class="tab-content" id="pills-tabContent">

                  <!-- Recent Jobs / ბოლო ვაკანსიები -->
                  <div class="tab-pane fade show active" id="all-jobs" role="tabpanel"
                       aria-labelledby="recent-jobs-tab">

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>

                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="lastRecord in lastRecords" :key="lastRecord.id">
                      <div class="p-4">
                        <div class="row align-items-center custom-text">
                          <div class="col-md-2 col-12 mb-md-0 mb-3 text-center">


                            <div  v-if="lastRecord.CompanyDetail.logo!=''">
                              <a :href="'/VacancyDetails/'+lastRecord.id">
                                <img :src="lastRecord.CompanyDetail.logo" alt="" class="img-fluid rounded-3">
                              </a>
                            </div>
                            <div  v-else>
                              <a :href="'/VacancyDetails/'+lastRecord.id">
                                <img src="@/assets/images/no-logo.jpeg" alt="" style="height: 85px;" class="img-fluid rounded-3">
                              </a>
                            </div>
                          </div>


                          <div class="col-12 col-md-2 text-left text-md-center">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  {{ lastRecord.CompanyDetail.name }}
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ lastRecord.VacancyLocation.name }}
                              </p>

                            </div>
                          </div>


                          <div class="col-12 col-md-3 text-left text-md-center">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  {{ lastRecord.PositionDetail.text }}
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ lastRecord.IndustryDetail.text }}
                              </p>

                            </div>
                          </div>





                          <div class="col-8 col-md-3 text-left text-md-center" v-if="lastRecord.amount_type=='1'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                 {{ Number(lastRecord.avarage_min_bonus).toLocaleString() }} {{lastRecord.CurrencyDetail.text}} -  {{ Number(lastRecord.avarage_max_bonus).toLocaleString()  }} {{lastRecord.CurrencyDetail.text}}
                              </p>

                            </div>
                          </div>

                          <div class="col-8 col-md-3 text-left text-md-center" v-if="lastRecord.amount_type=='2'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ Number(lastRecord.min_amount).toLocaleString() }} {{ lastRecord.CurrencyDetail.text }} - {{ Number(lastRecord.max_amount).toLocaleString() }} {{ lastRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-8 col-md-3 mt-2 mt-md-0 text-left text-md-center" v-if="lastRecord.amount_type=='3'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ Number(lastRecord.fixed_amount).toLocaleString() }} {{ lastRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>





                          <!--end col-->
                          <div class="col-4 col-md-2 text-center">
                            <div>

                              <span v-if="lastRecord.work_schedule=='remote'" class="badge bg-soft-blue  fs-13 mt-1">დისტანციური</span>
                              <span v-if="lastRecord.work_schedule=='full'" class="badge bg-soft-green-dark  fs-13 mt-1">სრული განაკვეთი</span>
                              <span v-if="lastRecord.work_schedule=='half'" class="badge bg-soft-warning  fs-13 mt-1">ნახევარი განაკვეთი</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-10">
                            <div>
                              <p class="text-muted mb-0" v-if="lastRecord.work_experience=='1'">
                                <span class="text-dark">გამოცდილება :</span> გამოცდილების გარეშე
                              </p>

                              <p class="text-muted mb-0" v-if="lastRecord.work_experience=='2'">
                                <span class="text-dark">გამოცდილება :</span> დამწყები
                              </p>


                              <p class="text-muted mb-0" v-if="lastRecord.work_experience=='3'">
                                <span class="text-dark">გამოცდილება :</span> საშუალო დონე
                              </p>


                              <p class="text-muted mb-0" v-if="lastRecord.work_experience=='4'">
                                <span class="text-dark">გამოცდილება :</span> პროფესიონალი
                              </p>
                            </div>
                          </div>
                          <div class="col-md-2 text-md-center text-left">
                            <div class="">
                              <a :href="'/VacancyDetails/'+lastRecord.id" class="primary-link">დეტალურად
                                <i class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->

                    <div class="text-center mt-4 pt-2">
                      <a href="VacancyList" class="btn btn-custom-primary btn-hover">ნახე მეტი <i class="uil uil-arrow-right"></i></a>
                    </div>

                  </div>
                  <!--end Recent Jobs / ბოლო ვაკანსიები -->

                  <!-- Recent Jobs / ბოლო ვაკანსიები -->
                  <div class="tab-pane fade show " id="recent-jobs" role="tabpanel"
                       aria-labelledby="recent-jobs-tab">

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>

                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="lastRecord in lastRecords" :key="lastRecord.id">
                      <div class="p-4">
                        <div class="row align-items-center">
                          <div class="col-md-2">

                            <div class="d-none d-sm-block" v-if="lastRecord.CompanyDetail.logo!=''">
                              <a :href="'/VacancyDetails/'+lastRecord.id"><img :src="lastRecord.CompanyDetail.logo" alt="" class="img-fluid rounded-3"></a>
                            </div>
                            <div class="d-none d-sm-block" v-else>
                              <a :href="'/VacancyDetails/'+lastRecord.id"><img src="../../assets/images/no-logo.jpeg" alt="" class="img-fluid rounded-3"></a>
                            </div>
                          </div>


                          <div class="col-md-3">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  {{ lastRecord.PositionDetail.text }}
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ lastRecord.IndustryDetail.text }}
                              </p>
                              <p class="text-muted fs-14 mb-0">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">{{ lastRecord.CompanyDetail.name }}</a>
                              </p>
                            </div>
                          </div>


                          <div class="col-6 col-md-2" v-if="lastRecord.amount_type=='1'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ lastRecord.fixed_amount }} {{ lastRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-3" v-if="lastRecord.amount_type=='1'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  ბონუსი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ lastRecord.min_bonus }} {{ lastRecord.CurrencyDetail.text }} - {{ lastRecord.max_bonus }} {{ lastRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-5" v-if="lastRecord.amount_type=='2'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ lastRecord.min_amount }} {{ lastRecord.CurrencyDetail.text }} - {{ lastRecord.max_amount }} {{ lastRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-5" v-if="lastRecord.amount_type=='3'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+lastRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ lastRecord.fixed_amount }} {{ lastRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>





                          <!--end col-->
                          <div class="col-md-2">
                            <div>

                              <span v-if="lastRecord.work_schedule=='remote'" class="badge bg-soft-blue float-end fs-13 mt-1">დისტანციური</span>
                              <span v-if="lastRecord.work_schedule=='full'" class="badge bg-soft-green-dark float-end fs-13 mt-1">სრული განაკვეთი</span>
                              <span v-if="lastRecord.work_schedule=='half'" class="badge bg-soft-warning float-end fs-13 mt-1">ნახევარი განაკვეთი</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <p class="text-muted mb-0" v-if="lastRecord.work_experience=='1'">
                                <span class="text-dark">გამოცდილება :</span> გამოცდილების გარეშე
                              </p>

                              <p class="text-muted mb-0" v-if="lastRecord.work_experience=='2'">
                                <span class="text-dark">გამოცდილება :</span> დამწყები
                              </p>


                              <p class="text-muted mb-0" v-if="lastRecord.work_experience=='3'">
                                <span class="text-dark">გამოცდილება :</span> საშუალო დონე
                              </p>


                              <p class="text-muted mb-0" v-if="lastRecord.work_experience=='4'">
                                <span class="text-dark">გამოცდილება :</span> პროფესიონალი
                              </p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="text-start text-md-end">
                              <a :href="'/VacancyDetails/'+lastRecord.id" class="primary-link">დეტალურად <i
                                  class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->

                    <div class="text-center mt-4 pt-2">
                      <a href="VacancyList" class="btn btn-custom-primary btn-hover">ნახე მეტი <i class="uil uil-arrow-right"></i></a>
                    </div>

                  </div>
                  <!--end Recent Jobs / ბოლო ვაკანსიები -->

                  <!-- Remote Jobs / დისტანციური ვაკანსიები -->
                  <div class="tab-pane fade" id="remote-jobs" role="tabpanel"
                       aria-labelledby="remote-jobs-tab">

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>


                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="remoteRecord in remoteRecords" :key="remoteRecord.id">
                      <div class="p-4">
                        <div class="row align-items-center">
                          <div class="col-md-2">

                            <div class="d-none d-sm-block" v-if="remoteRecord.CompanyDetail.logo!=''">
                              <a :href="'/VacancyDetails/'+remoteRecord.id"><img :src="remoteRecord.CompanyDetail.logo" alt="" class="img-fluid rounded-3"></a>
                            </div>
                            <div class="d-none d-sm-block" v-else>
                              <a :href="'/VacancyDetails/'+remoteRecord.id"><img src="../../assets/images/no-logo.jpeg" alt="" class="img-fluid rounded-3"></a>
                            </div>
                          </div>


                          <div class="col-md-3">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+remoteRecord.id" class="text-dark">
                                  {{ remoteRecord.PositionDetail.text }}
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ remoteRecord.IndustryDetail.text }}
                              </p>
                              <p class="text-muted fs-14 mb-0">
                                <a :href="'/VacancyDetails/'+remoteRecord.id" class="text-dark">{{ remoteRecord.CompanyDetail.name }}</a>
                              </p>
                            </div>
                          </div>


                          <div class="col-6 col-md-2" v-if="remoteRecord.amount_type=='1'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+remoteRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ remoteRecord.fixed_amount }} {{ remoteRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-3" v-if="remoteRecord.amount_type=='1'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+remoteRecord.id" class="text-dark">
                                  ბონუსი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ remoteRecord.min_bonus }} {{ remoteRecord.CurrencyDetail.text }} - {{ remoteRecord.max_bonus }} {{ remoteRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-5" v-if="remoteRecord.amount_type=='2'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+remoteRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ remoteRecord.min_amount }} {{ remoteRecord.CurrencyDetail.text }} - {{ remoteRecord.max_amount }} {{ remoteRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-5" v-if="remoteRecord.amount_type=='3'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+remoteRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ remoteRecord.fixed_amount }} {{ remoteRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>





                          <!--end col-->
                          <div class="col-md-2">
                            <div>

                              <span v-if="remoteRecord.work_schedule=='remote'" class="badge bg-soft-blue float-end fs-13 mt-1">დისტანციური</span>
                              <span v-if="remoteRecord.work_schedule=='full'" class="badge bg-soft-green-dark float-end fs-13 mt-1">სრული განაკვეთი</span>
                              <span v-if="remoteRecord.work_schedule=='half'" class="badge bg-soft-warning float-end fs-13 mt-1">ნახევარი განაკვეთი</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <p class="text-muted mb-0" v-if="remoteRecord.work_experience=='1'">
                                <span class="text-dark">გამოცდილება :</span> გამოცდილების გარეშე
                              </p>

                              <p class="text-muted mb-0" v-if="remoteRecord.work_experience=='2'">
                                <span class="text-dark">გამოცდილება :</span> დამწყები
                              </p>


                              <p class="text-muted mb-0" v-if="remoteRecord.work_experience=='3'">
                                <span class="text-dark">გამოცდილება :</span> საშუალო დონე
                              </p>


                              <p class="text-muted mb-0" v-if="remoteRecord.work_experience=='4'">
                                <span class="text-dark">გამოცდილება :</span> პროფესიონალი
                              </p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="text-start text-md-end">
                              <a :href="'/VacancyDetails/'+remoteRecord.id" class="primary-link">დეტალურად <i
                                  class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->


                    <div class="text-center mt-4 pt-2">
                      <a href="VacancyDetails" class="btn btn-custom-primary btn-hover">ნახე მეტი <i class="uil uil-arrow-right"></i></a>
                    </div>
                  </div>
                  <!--end featured-jobs-tab-->

                  <!-- Part Time Jobs / ნახევარი განაკვეთის ვაკანსიები -->
                  <div class="tab-pane fade" id="part-time" role="tabpanel" aria-labelledby="part-time-tab">

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>

                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="halfRecord in halfRecords" :key="halfRecord.id">
                      <div class="p-4">
                        <div class="row align-items-center">
                          <div class="col-md-2">

                            <div class="d-none d-sm-block" v-if="halfRecord.CompanyDetail.logo!=''">
                              <a :href="'/VacancyDetails/'+halfRecord.id"><img :src="halfRecord.CompanyDetail.logo" alt="" class="img-fluid rounded-3"></a>
                            </div>
                            <div class="d-none d-sm-block" v-else>
                              <a :href="'/VacancyDetails/'+halfRecord.id"><img src="../../assets/images/no-logo.jpeg" alt="" class="img-fluid rounded-3"></a>
                            </div>
                          </div>


                          <div class="col-md-3">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+halfRecord.id" class="text-dark">
                                  {{ halfRecord.PositionDetail.text }}
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ halfRecord.IndustryDetail.text }}
                              </p>
                              <p class="text-muted fs-14 mb-0">
                                <a :href="'/VacancyDetails/'+halfRecord.id" class="text-dark">{{ halfRecord.CompanyDetail.name }}</a>
                              </p>
                            </div>
                          </div>


                          <div class="col-6 col-md-2" v-if="halfRecord.amount_type=='1'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+halfRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ halfRecord.fixed_amount }} {{ halfRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-3" v-if="halfRecord.amount_type=='1'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+halfRecord.id" class="text-dark">
                                  ბონუსი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ halfRecord.min_bonus }} {{ halfRecord.CurrencyDetail.text }} - {{ halfRecord.max_bonus }} {{ halfRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-5" v-if="halfRecord.amount_type=='2'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+halfRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ halfRecord.min_amount }} {{ halfRecord.CurrencyDetail.text }} - {{ halfRecord.max_amount }} {{ halfRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-5" v-if="halfRecord.amount_type=='3'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+halfRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ halfRecord.fixed_amount }} {{ halfRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>





                          <!--end col-->
                          <div class="col-md-2">
                            <div>

                              <span v-if="halfRecord.work_schedule=='remote'" class="badge bg-soft-blue float-end fs-13 mt-1">დისტანციური</span>
                              <span v-if="halfRecord.work_schedule=='full'" class="badge bg-soft-green-dark float-end fs-13 mt-1">სრული განაკვეთი</span>
                              <span v-if="halfRecord.work_schedule=='half'" class="badge bg-soft-warning float-end fs-13 mt-1">ნახევარი განაკვეთი</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <p class="text-muted mb-0" v-if="halfRecord.work_experience=='1'">
                                <span class="text-dark">გამოცდილება :</span> გამოცდილების გარეშე
                              </p>

                              <p class="text-muted mb-0" v-if="halfRecord.work_experience=='2'">
                                <span class="text-dark">გამოცდილება :</span> დამწყები
                              </p>


                              <p class="text-muted mb-0" v-if="halfRecord.work_experience=='3'">
                                <span class="text-dark">გამოცდილება :</span> საშუალო დონე
                              </p>


                              <p class="text-muted mb-0" v-if="halfRecord.work_experience=='4'">
                                <span class="text-dark">გამოცდილება :</span> პროფესიონალი
                              </p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="text-start text-md-end">
                              <a :href="'/VacancyDetails/'+halfRecord.id" class="primary-link">დეტალურად <i
                                  class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->

                    <div class="text-center mt-4 pt-2">
                      <a href="VacancyDetails" class="btn btn-custom-primary btn-hover">ნახე მეტი <i class="uil uil-arrow-right"></i></a>
                    </div>
                  </div>
                  <!--end Part Time Jobs / ნახევარი განაკვეთის ვაკანსიები-->

                  <!-- Full Time Jobs / სრული განაკვეთის ვაკანსიები -->
                  <div class="tab-pane fade" id="full-time" role="tabpanel" aria-labelledby="full-time-tab">

                    <div v-if="loading">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>


                    <!--job-box-->
                    <div class="job-box card mt-4" v-for="fullRecord in fullRecords" :key="fullRecord.id">
                      <div class="p-4">
                        <div class="row align-items-center">
                          <div class="col-md-2">


                            <div class="d-none d-sm-block" v-if="fullRecord.CompanyDetail.logo!=''">
                              <a :href="'/VacancyDetails/'+fullRecord.id">
                                <img :src="fullRecord.CompanyDetail.logo" alt="" class="img-fluid rounded-3">
                              </a>
                            </div>
                            <div class="d-none d-sm-block" v-else>
                              <a :href="'/VacancyDetails/'+fullRecord.id">
                                <img src="@/assets/images/no-logo.jpeg" alt="" class="img-fluid rounded-3">
                              </a>
                            </div>
                          </div>


                          <div class="col-md-3">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+fullRecord.id" class="text-dark">
                                  {{ fullRecord.PositionDetail.text }}
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ fullRecord.IndustryDetail.text }}
                              </p>
                              <p class="text-muted fs-14 mb-0">
                                <a :href="'/VacancyDetails/'+fullRecord.id" class="text-dark">{{ fullRecord.CompanyDetail.name }}</a>
                              </p>
                            </div>
                          </div>


                          <div class="col-6 col-md-2" v-if="fullRecord.amount_type=='1'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+fullRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ fullRecord.fixed_amount }} {{ fullRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-3" v-if="fullRecord.amount_type=='1'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+fullRecord.id" class="text-dark">
                                  ბონუსი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ fullRecord.min_bonus }} {{ fullRecord.CurrencyDetail.text }} - {{ fullRecord.max_bonus }} {{ fullRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-5" v-if="fullRecord.amount_type=='2'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+fullRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ fullRecord.min_amount }} {{ fullRecord.CurrencyDetail.text }} - {{ fullRecord.max_amount }} {{ fullRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>

                          <div class="col-6 col-md-5" v-if="fullRecord.amount_type=='3'">
                            <div class="mb-2 mb-md-0">
                              <h5 class="fs-18 mb-1">
                                <a :href="'/VacancyDetails/'+fullRecord.id" class="text-dark">
                                  შემოსავალი
                                </a>
                              </h5>
                              <p class="text-muted fs-14 mb-0">
                                {{ fullRecord.fixed_amount }} {{ fullRecord.CurrencyDetail.text }}
                              </p>

                            </div>
                          </div>





                          <!--end col-->
                          <div class="col-md-2">
                            <div>

                              <span v-if="fullRecord.work_schedule=='remote'" class="badge bg-soft-blue float-end fs-13 mt-1">დისტანციური</span>
                              <span v-if="fullRecord.work_schedule=='full'" class="badge bg-soft-green-dark float-end fs-13 mt-1">სრული განაკვეთი</span>
                              <span v-if="fullRecord.work_schedule=='half'" class="badge bg-soft-warning float-end fs-13 mt-1">ნახევარი განაკვეთი</span>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                      <div class="p-3 bg-light">
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <p class="text-muted mb-0" v-if="fullRecord.work_experience=='1'">
                                <span class="text-dark">გამოცდილება :</span> გამოცდილების გარეშე
                              </p>

                              <p class="text-muted mb-0" v-if="fullRecord.work_experience=='2'">
                                <span class="text-dark">გამოცდილება :</span> დამწყები
                              </p>


                              <p class="text-muted mb-0" v-if="fullRecord.work_experience=='3'">
                                <span class="text-dark">გამოცდილება :</span> საშუალო დონე
                              </p>


                              <p class="text-muted mb-0" v-if="fullRecord.work_experience=='4'">
                                <span class="text-dark">გამოცდილება :</span> პროფესიონალი
                              </p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="text-start text-md-end">
                              <a :href="'/VacancyDetails/'+fullRecord.id" class="primary-link">დეტალურად <i
                                  class="mdi mdi-chevron-double-right"></i></a>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </div>
                    </div>
                    <!--end job-box-->




                    <div class="text-center mt-4 pt-2">
                      <a href="VacancyDetails" class="btn btn-custom-primary btn-hover">ნახე მეტი <i class="uil uil-arrow-right"></i></a>
                    </div>
                  </div>
                  <!--end Full Time Jobs / სრული განაკვეთის ვაკანსიები-->

                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </section>
        <!-- END JOB-LIST -->

        <!-- START APPLY MODAL -->
        <div class="modal fade" id="applyNow" tabindex="-1" aria-labelledby="applyNow" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body p-5">
                <div class="text-center mb-4">
                  <h5 class="modal-title" id="staticBackdropLabel">Apply For This Job</h5>
                </div>
                <div class="position-absolute end-0 top-0 p-3">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mb-3">
                  <label for="nameControlInput" class="form-label">Name</label>
                  <input type="text" class="form-control" id="nameControlInput" placeholder="Enter your name">
                </div>
                <div class="mb-3">
                  <label for="emailControlInput2" class="form-label">Email Address</label>
                  <input type="email" class="form-control" id="emailControlInput2" placeholder="Enter your email">
                </div>
                <div class="mb-3">
                  <label for="mobileControlInput2" class="form-label">Mobile</label>
                  <input type="number" class="form-control" id="mobileControlInput2" placeholder="Enter Your number">
                </div>
                <div class="mb-3">
                  <label class="form-label">Message</label>
                  <textarea class="form-control"></textarea>
                </div>
                <div class="mb-4">
                  <label class="form-label" for="inputGroupFile01">Upload Your CV</label>
                  <input type="file" class="form-control" id="inputGroupFile01">
                </div>
                <button type="submit" class="btn btn-orange w-100">Send Application</button>
              </div>
            </div>
          </div>
        </div><!-- END APPLY MODAL -->

      </div>
      <!-- End Page-content -->

      <Footer/>

      <!--start back-to-top-->
      <button onclick="topFunction()" id="back-to-top">
        <i class="mdi mdi-arrow-up"></i>
      </button>
      <!--end back-to-top-->
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: "Main",
  data() {
    return {
      lastRecords: null,
      remoteRecords: null,
      halfRecords: null,
      fullRecords: null,
      loading: true,
      errored: false
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    lastRecord() {
      axios
      .get('https://recruting.dkcapital.ge/api/public/vacancy/get_last_records')
      .then(res => {
        this.lastRecords = res.data
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.loading = false)
      },
    remoteRecord() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/get_record_statuses/remote')
          .then(res => {
            this.remoteRecords = res.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    halfRecord() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/get_record_statuses/half')
          .then(res => {
            this.halfRecords = res.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fullRecord() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/vacancy/get_record_statuses/full')
          .then(res => {
            this.fullRecords = res.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    }
    },
  mounted() {
    this.lastRecord();
    this.remoteRecord();
    this.halfRecord();
    this.fullRecord();
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .custom-text .fs-18 {
    font-size: 14px;
  }
  .custom-text .text-muted {
    font-size: 13px;
  }
}
</style>