<template>
  <div>
    <Header/>


    <div class="main-content">

      <div class="page-content">

        <!-- START JOB-LIST -->
        <section class="section mt-md-4">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-3">
                <h4 class="mb-1">ვაკანსიები</h4>
              </div>
              <div class="col-12">
                <div class="me-lg-5">
                  <div class="job-list-header">
                    <form action="#">
                      <div class="row g-2">
                        <div class="col-12 col-md-4">
                          <div class="filler-job-form">

                            <v-select v-model="filter.location_id"   class="lss-form-select" placeholder="აირჩიეთ ლოკაცია" label="text" :options="locationDatas"></v-select>

                          </div>
                        </div><!--end col-->
                        <div class="col-12 col-md-4">
                          <div class="filler-job-form">
                            <v-select v-model="filter.industry_id"  @change="getResults();" class="lss-form-select" placeholder="აირჩიეთ კატეგორია" label="text" :options="industryDatas"></v-select>


                          </div>
                        </div>
                        <div class="col-12 col-md-3">
                          <div class="filler-job-form">
                            <v-select v-model="filter.work_experience" class="lss-form-select" placeholder="აირჩიე გრაფიკი"  :options="
                           [{ label: 'დისტანციური', id: 'remote' },
                           { label: 'ნახევარი განაკვეთი', id: 'half' },
                           { label: 'სრული განაკვეთი', id: 'full' }]"></v-select>

                          </div>
                        </div>
                        <div class="col-12 col-md-1">
                          <div class="btn btn-blue btn-sm float-end w-100" @click="getResults()" style="height: 35px;line-height: 27px;">ძებნა</div>
                        </div>
                      </div><!--end row-->
                    </form>
                  </div><!--end job-list-header-->
                  <!-- Job-list -->
                  <div>

                    <div v-if="isLoading" style="margin-top:300px;">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>




                    <div  style="min-height:500px;">






                      <div class="job-box card mt-4" v-if="!isLoading" v-for="vacancyList in ResponseData.data" :key="vacancyList.id">
                        <div class="p-4">
                          <div class="row align-items-center custom-text">
                            <div class="col-md-2 col-12 mb-md-0 mb-3 text-center">

                              <div  v-if="vacancyList.CompanyDetail.logo!=''">
                                <a :href="'/VacancyDetails/'+vacancyList.id">
                                  <img :src="vacancyList.CompanyDetail.logo" alt="" class="img-fluid rounded-3">
                                </a>
                              </div>
                              <div  v-else>
                                <a :href="'/VacancyDetails/'+vacancyList.id"><img src="../../assets/images/no-logo.jpeg" alt="" class="img-fluid rounded-3"></a>
                              </div>
                            </div>


                            <div class="col-12 col-md-2 mt-2 mt-md-0 text-left text-md-center">
                              <div class="mb-2 mb-md-0">
                                <h5 class="fs-18 mb-1">
                                  <a :href="'/VacancyDetails/'+vacancyList.id" class="text-dark">
                                    {{ vacancyList.CompanyDetail.name }}
                                  </a>
                                </h5>
                                <p class="text-muted fs-14 mb-0">
                                  {{ vacancyList.VacancyLocation.name }}
                                </p>

                              </div>
                            </div>


                            <div class="col-12 col-md-3 mt-2 mt-md-0 text-left text-md-center">
                              <div class="mb-2 mb-md-0">
                                <h5 class="fs-18 mb-1">
                                  <a :href="'/VacancyDetails/'+vacancyList.id" class="text-dark">
                                    {{ vacancyList.PositionDetail.text }}
                                  </a>
                                </h5>
                                <p class="text-muted fs-14 mb-0">
                                  {{ vacancyList.IndustryDetail.text }}
                                </p>

                              </div>
                            </div>





                            <div class="col-8 col-md-3 mt-2 mt-md-0 text-left text-md-center" v-if="vacancyList.amount_type=='1'">
                              <div class="mb-2 mb-md-0">
                                <h5 class="fs-18 mb-1">
                                  <a :href="'/VacancyDetails/'+vacancyList.id" class="text-dark">
                                    შემოსავალი
                                  </a>
                                </h5>
                                <p class="text-muted fs-14 mb-0">
                                  {{ Number(vacancyList.avarage_min_bonus).toLocaleString() }} {{vacancyList.CurrencyDetail.text}} -  {{ Number(vacancyList.avarage_max_bonus).toLocaleString()  }} {{vacancyList.CurrencyDetail.text}}
                                </p>

                              </div>
                            </div>

                            <div class="col-8 col-md-3 mt-2 mt-md-0 text-left text-md-center" v-if="vacancyList.amount_type=='2'">
                              <div class="mb-2 mb-md-0">
                                <h5 class="fs-18 mb-1">
                                  <a :href="'/VacancyDetails/'+vacancyList.id" class="text-dark">
                                    შემოსავალი
                                  </a>
                                </h5>
                                <p class="text-muted fs-14 mb-0">
                                  {{ Number(vacancyList.min_amount).toLocaleString() }} {{ vacancyList.CurrencyDetail.text }} - {{ Number(vacancyList.max_amount).toLocaleString() }} {{ vacancyList.CurrencyDetail.text }}
                                </p>

                              </div>
                            </div>

                            <div class="col-8 col-md-3 mt-2 mt-md-0 text-left text-md-center" v-if="vacancyList.amount_type=='3'">
                              <div class="mb-2 mb-md-0">
                                <h5 class="fs-18 mb-1">
                                  <a :href="'/VacancyDetails/'+vacancyList.id" class="text-dark">
                                    შემოსავალი
                                  </a>
                                </h5>
                                <p class="text-muted fs-14 mb-0">
                                  {{ Number(vacancyList.fixed_amount).toLocaleString() }} {{ vacancyList.CurrencyDetail.text }}
                                </p>

                              </div>
                            </div>





                            <!--end col-->
                            <div class="col-4 col-md-2 text-center">
                              <div>

                                <span v-if="vacancyList.work_schedule=='remote'" class="badge bg-soft-blue  fs-13 mt-1">დისტანციური</span>
                                <span v-if="vacancyList.work_schedule=='full'" class="badge bg-soft-green-dark  fs-13 mt-1">სრული განაკვეთი</span>
                                <span v-if="vacancyList.work_schedule=='half'" class="badge bg-soft-warning  fs-13 mt-1">ნახევარი განაკვეთი</span>
                              </div>
                            </div>
                            <!--end col-->
                          </div>
                          <!--end row-->
                        </div>
                        <div class="p-3 bg-light">
                          <div class="row">
                            <div class="col-md-10">
                              <div>
                                <p class="text-muted mb-0" v-if="vacancyList.work_experience=='1'">
                                  <span class="text-dark">გამოცდილება :</span> გამოცდილების გარეშე
                                </p>

                                <p class="text-muted mb-0" v-if="vacancyList.work_experience=='2'">
                                  <span class="text-dark">გამოცდილება :</span> დამწყები
                                </p>


                                <p class="text-muted mb-0" v-if="vacancyList.work_experience=='3'">
                                  <span class="text-dark">გამოცდილება :</span> საშუალო დონე
                                </p>


                                <p class="text-muted mb-0" v-if="vacancyList.work_experience=='4'">
                                  <span class="text-dark">გამოცდილება :</span> პროფესიონალი
                                </p>
                              </div>
                            </div>
                            <div class="col-md-2 text-md-center text-left">
                              <div class="">
                                <a :href="'/VacancyDetails/'+vacancyList.id" class="primary-link">დეტალურად
                                  <i class="mdi mdi-chevron-double-right"></i></a>
                              </div>
                            </div>
                            <!--end col-->
                          </div>
                          <!--end row-->
                        </div>
                      </div>


                    </div>



                  </div>
                  <!-- End Job-list -->
                  <div class="row">
                    <div class="col-lg-12 mt-4 pt-2">
                      <pagination :data="ResponseData" 	 @pagination-change-page="getResults">
                        <template #prev-nav>
                          <span> < </span>
                        </template>
                        <template #next-nav>
                          <span> > </span>
                        </template>
                      </pagination>
                    </div><!--end col-->
                  </div><!-- end row -->
                </div>
              </div><!--end col-->

              <!-- START SIDE-BAR -->
              <!-- END SIDE-BAR -->
            </div><!--end row-->
          </div><!--end container-->
        </section>
        <!-- END JOB-LIST -->

        <!-- START APPLY MODAL -->
        <div class="modal fade" id="applyNow" tabindex="-1" aria-labelledby="applyNow" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body p-5">
                <div class="text-center mb-4">
                  <h5 class="modal-title" id="staticBackdropLabel">Apply For This Job</h5>
                </div>
                <div class="position-absolute end-0 top-0 p-3">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mb-3">
                  <label for="nameControlInput" class="form-label">Name</label>
                  <input type="text" class="form-control" id="nameControlInput" placeholder="Enter your name">
                </div>
                <div class="mb-3">
                  <label for="emailControlInput2" class="form-label">Email Address</label>
                  <input type="email" class="form-control" id="emailControlInput2" placeholder="Enter your email">
                </div>
                <div class="mb-3">
                  <label  class="form-label">Message</label>
                  <textarea class="form-control" id="messageControlTextarea" rows="4" placeholder="Enter your message"></textarea>
                </div>
                <div class="mb-4">
                  <label class="form-label" for="inputGroupFile01">Resume Upload</label>
                  <input type="file" class="form-control" id="inputGroupFile01">
                </div>
                <button type="submit" class="btn btn-primary w-100">Send Application</button>
              </div>
            </div>
          </div>
        </div><!-- END APPLY MODAL -->

      </div>
      <!-- End Page-content -->
    </div>
    <!-- end main content-->
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "VacancyDetails",
  data() {
    return {

      vacancyLists: null,
      locationDatas: [],
      industryDatas: [],
      loading: true,
      isLoading:'',
      errored: false,
      ResponseData:{},
      filter:{

      }
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {

    locationData() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/LoadDataLocations')
          .then(response => {
            this.locationDatas = response.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    industryData() {
      axios
          .get('https://recruting.dkcapital.ge/api/public/LoadSphere')
          .then(response => {
            this.industryDatas = response.data
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },

    getResults(page = 1) {

      this.isLoading = true;
      axios.get('https://recruting.dkcapital.ge/api/public/vacancy/search'+'?page=' + page, {
        params: {
          location_id: this.filter.location_id,
          industry_id: this.filter.industry_id,
          work_experience: this.filter.work_experience,

        }
      })
          .then(response => {
            this.isLoading = false;
            this.ResponseData = response.data.vacancy;
          });
    },



  },
  mounted () {
    this.getResults();
    this.locationData();
    this.industryData();
  },
}
</script>

<style >
.sr-only{
  display:none!important;
}
@media screen and (max-width: 768px) {
  .custom-text .fs-18 {
    font-size: 14px;
  }
  .custom-text .text-muted {
    font-size: 13px;
  }
}
</style>