<template>
  <div>
    <Header/>
    <div class="main-content">
      <div class="page-content">
        <section class="section job-leads mt-4">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-3 mt-3 text-center">
                <h1>შეავსე ფორმა და მიიღე სამუშაო შემოთავაზებები</h1>
              </div>
              <div class="col-12 mt-4">
                <p class="font-weight-bold">სახელი და გვარი</p>
                <input type="text" class="form-control" placeholder="სახელი და გვარი">
              </div>
              <div class="col-12 mt-4">
                <p class="font-weight-bold">ელ.ფოსტა</p>
                <input type="email" class="form-control" placeholder="ელ.ფოსტა">
              </div>
              <div class="col-12 mt-4">
                <p class="font-weight-bold">მობილური ტელეფონი</p>
                <input type="number" class="form-control" placeholder="ნომერი">
              </div>
              <div class="col-12 mt-4">
                <label class="form-label font-weight-bold">აირჩიე სამუშაო გრაფიკი</label>
                <div class="filler-job-form">
                  <i class="uil uil-clipboard"></i>
                  <select class="form-select" data-trigger name="choices-single-location" id="choices-single-location" aria-label="Default select example">
                    <option value="location" selected disabled>აირჩიე სამუშაო გრაფიკი</option>
                    <option value="remote">დისტანციური</option>
                    <option value="halfTime">ნახევარი განაკვეთი</option>
                    <option value="fullTime">სრული განაკვეთი</option>
                  </select>
                </div>
              </div>
              <div class="col-12 mt-4">
                <label class="form-label font-weight-bold">მიუთითე სასურველი ანაზღაურება</label>
              </div>
              <div class="col-6 col-sm-4 col-md-5">
                <input type="number" class="form-control" id="mobileControlInput2" placeholder="-დან">
              </div>
              <div class="col-6 col-sm-4 col-md-5">
                <input type="number" class="form-control" id="mobileControlInput2" placeholder="-მდე">
              </div>
              <div class="col-6 col-sm-4 col-md-2 mt-4 mt-md-0">
                <div class="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="btnradio" id="gel" autocomplete="off" checked>
                  <label class="btn btn-outline-primary" for="gel">₾</label>

                  <input type="radio" class="btn-check" name="btnradio" id="dollar" autocomplete="off">
                  <label class="btn btn-outline-primary" for="dollar">$</label>
                </div>
              </div>
              <div class="col-12 mt-5">
                <p class="text-center font-weight-bold">აირჩიეთ თქვენი პროფესიების ჩამონათვალი</p>
                <input type="text" class="form-control" v-model="search" placeholder="მოძებნე სამუშაო">
                <div class="overflow-auto mt-4">
                  <posts v-for="(item, index) in filteredPosts" :key="index" :data="item" @getId="workType" ></posts>
                </div>
              </div>
              <div class="col-12 mt-4">
                <button class="btn btn-custom-primary mdi mdi-rocket-launch-outline"> გაგზავნა</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import posts from '@/components/posts.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import prData from '../../product.json'
import store from '../../store/index.js'

export default {
  name: "Singup",
  components: {
    Header,
    Footer,
    posts
  },
  data() {
    return {
      currentlyData: prData,
      search: '',
      addItem: null
    }
  },
  methods: {
    workType() {
      this.addItem = {
        id: this.currentlyData[0].id,
        title: this.currentlyData[0].title
      }
      store.commit('setProduct', this.addItem)
      localStorage.setItem( 'cartItem', JSON.stringify(store.state.cartProducts))
      console.log(this.addItem)
    }
  },
  computed: {
    filteredPosts() {
      return this.currentlyData.filter(post => post.title.toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>

<style scoped>

</style>