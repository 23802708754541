import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cartProducts: [],
  },
  mutations: {
    setProduct(state, val) {
      state.cartProducts.push(val)
    }
  },
  actions: {
  },
  modules: {
  }
})
