import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/visible/Home.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import VacancyList from '../views/visible/VacancyList.vue'
import VacancyDetails from '../views/visible/VacancyDetails.vue'
import VacancyInfo from '../views/visible/VacancyInfo.vue'
import Singup from '../views/visible/Singup.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/VacancyList',
    name: 'VacancyList',
    component: VacancyList
  },
  {
    path: '/VacancyDetails/:id',
    name: 'VacancyDetails',
    component: VacancyDetails
  },
  {
    path: '/VacancyInfo',
    name: 'VacancyInfo',
    component: VacancyInfo
  },
  {
    path: '/Singup',
    name: 'Singup',
    component: Singup
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
