<template>
  <article>
    <div class="jobs-btn-group">
    <input type="checkbox" class="btn-check" :id="data.id" autocomplete="off" @click="sendId">
    <label class="btn btn-outline-primary" :for="data.id">{{data.title}}</label>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
      type: Object
    },
  },
  methods: {
    sendId() {
      this.$emit( 'getId', this.data.id)
    }
  }

}
</script>

<style scoped>

</style>