<template>
  <div>
    <!--Navbar Start-->
    <nav class="navbar navbar-expand-lg fixed-top sticky" id="navbar">
      <div class="container custom-container">
        <a class="navbar-brand text-light fw-bold me-auto" href="/">
          David Kenchadze
        </a>
        <div>
          <button style="color:#fff;" class="navbar-toggler me-3" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-label="Toggle navigation">
            <i class="mdi mdi-menu"></i>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="navbarCollapse">

          <ul class="navbar-nav ms-auto navbar-center">
            <li class="nav-item d-none d-sm-block">
              <a href="/" class="nav-link text-light">მთავარი</a>
            </li>
            <li class="nav-item  d-none d-sm-block">
              <a href="VacancyInfo" class="nav-link text-light" style="color: #0080ff">ვაკანსიის განთავსება</a>
            </li>

            <li class="nav-item d-block d-sm-none">
              <a href="/" class="nav-link text-light" style="color:black!important;">მთავარი</a>
            </li>
            <li class="nav-item  d-block d-sm-none">
              <a href="VacancyInfo" class="nav-link text-light" style="color:black!important;">ვაკანსიის განთავსება</a>
            </li>
          </ul><!--end navbar-nav-->




        </div>





        <!--end navabar-collapse-->
      </div>
      <!--end container-->
    </nav>
    <!-- Navbar End -->
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>