import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/style.css'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/icons.min.css'
import '@/assets/js/app.js'
import '@/assets/libs/bootstrap/js/bootstrap.bundle.min.js'
import "vue-select/dist/vue-select.css";
window.axios = require('axios');
import { ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full';
import {ValidationObserver} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules';
import vSelect from 'vue-select'



Vue.component("v-select", vSelect);
Vue.component('pagination', require('laravel-vue-pagination'));

Vue.config.productionTip = false
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
